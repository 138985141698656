import { useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';
import { FunctionAppType, Urls } from '../backend/urls';
import { httpGetJson, httpGetString, httpPost } from '../backend/http/http';
import { ITestRunTreeDescriptor } from '../../routes/home/ServiceHealthReportPopup';

export enum ServiceStatus {
  Good = 'Good',
  ServiceError = 'ServiceError',
  ServiceOutage = 'ServiceOutage',
}

export interface IServiceHealthSummary {
  name: string;
  lastUpdatedAt: string;
  status: ServiceStatus;
  possibleIssuesIfBroken: string[];
}

const BASE_TESTS_URL = 'https://pax-entry-service-health-monitor-beta-app-h4agb7gsbbhjcvga.uksouth-01.azurewebsites.net';

export function useServiceHealthReportInfo(testId?: string): ITestRunTreeDescriptor | undefined {
  const url = testId 
    ? `${BASE_TESTS_URL}/runTests/results/${testId}?onlyFailed=true`
    : undefined;

  // the data is immutable, so doesn't make sense to revalidate at all
  const { data } = useSWRAndHandleErrors<ITestRunTreeDescriptor>(url, httpGetJson, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
  });

  return data;
}

export function useServiceHealthStatus() {
  const url = `${BASE_TESTS_URL}/runTests/status`;
  const { data, mutate } = useSWRAndHandleErrors<string>(url, httpGetString);

  return { data: data == null ? undefined : data === 'true', mutate };
}

export function useServiceHealthSummary(): IServiceHealthSummary[] | undefined {
  const url = `${BASE_TESTS_URL}/serviceHealth/summary`;
  const { data } = useSWRAndHandleErrors<IServiceHealthSummary[]>(url, httpGetJson);
  return data;
}

interface IServiceHealthDetails {
  operationName: string;
  exception: string;
  errorDetails: string;
  errorsCount: number;
}

export function useServiceHealthDetails(functionApp: FunctionAppType) {
  const { data } = useSWRAndHandleErrors<IServiceHealthDetails[]>(Urls.ServiceHealthDetails(functionApp), httpGetJson);
  return data;
}
