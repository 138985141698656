import { useEffect, useState } from 'react';
import { getUrlWithQueryParams, httpGetJson } from '../backend/http/http';
import { debounce } from '../jsHelpers/jsHelpers';

export const PAGE_SIZE = 20;

export function getTotalPages(pageSize?: number, totalItems?: number): number {
  if (!pageSize || !totalItems) {
    return 0;
  }
  return Math.ceil(totalItems / pageSize);
}

interface IPageObject<TItem> {
  page: number;
  items: TItem[];
  isLoading: boolean;
}

function getPagesToLoad(page: number, maxPage: number): number[] {
  if (page <= 0) {
    throw new Error('Page cannot be less than 1');
  }

  let pages = [];
  if (page === 1) {
    pages = [1, 2, 3];
  } else if (page === maxPage) {
    pages = [maxPage - 2, maxPage - 1, maxPage];
  } else {
    pages = [page - 1, page, page + 1];
  }

  return pages.filter(page => page > 0 && page <= maxPage);
}

export function useInfiniteWithPagination<TItem>(
  url?: string,
  maxPage?: number,
  tableParams?: {
    scrollRef?: React.RefObject<HTMLDivElement | null>;
    adjustPage?: boolean;
  },
): {
  items: TItem[];
  loading: boolean;
  isCurrentPageLoading: boolean;
  setPage: (page: number) => void;
  reset: () => void;
  page: number;
} {
  if (url && (url.includes('page') || url.includes('pageSize'))) {
    throw new Error('Do not include page or pageSize in the url');
  }

  const [page, setPage] = useState<number | null>(null);
  const [sortedPages, setSortedPages] = useState<number[]>([]);
  const [loadedPageObjects, setLoadedPageObjects] = useState<{ [key: number]: IPageObject<TItem> }>({});
  const [requestToScrollIntoView, setRequestToScrollIntoView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isInResetState, setIsInResetState] = useState(false);
  const currPage = page || 1;

  // sync pages with current page
  useEffect(() => {
    if (url && maxPage) {
      if (!page) {
        setPage(currPage);
      }
      setSortedPages(prevPages => {
        const pagesToLoad = getPagesToLoad(currPage, maxPage);
        return [...new Set([...prevPages, ...pagesToLoad].sort((a, b) => a - b))];
      });
      setIsInResetState(false);
    } else {
      setSortedPages([]);
    }
  }, [url, maxPage, currPage, page]);

  // sync pages data with pages
  useEffect(() => {
    if (!url || maxPage == null) {
      return;
    }

    const newPages = sortedPages.filter(page => !loadedPageObjects[page]);
    if (newPages.length === 0 && requestToScrollIntoView) {
      if (tableParams?.scrollRef && loadedPageObjects[currPage]) {
        const scrollRef = tableParams.scrollRef.current;
        if (scrollRef) {
          const { scrollHeight, clientHeight } = scrollRef;
          const totalItemsOnAllPages = Object.values(loadedPageObjects).reduce(
            (acc, pageObject) => acc + pageObject.items.length,
            0,
          );
          const itemHeight = scrollHeight / totalItemsOnAllPages;
          const heightRequiredForCurrPage = itemHeight * loadedPageObjects[currPage].items.length;
          const heightRequiredForPrevPages = Object.values(loadedPageObjects).reduce((acc, pageObject) => {
            if (pageObject.page < currPage) {
              return acc + itemHeight * pageObject.items.length;
            }
            return acc;
          }, 0);

          if (totalItemsOnAllPages < PAGE_SIZE || currPage === 1) {
            scrollRef.scrollTop = 0;
          } else {
            scrollRef.scrollTop = heightRequiredForPrevPages - clientHeight + heightRequiredForCurrPage;
          }
        }
        setRequestToScrollIntoView(false);
      }
      return;
    }

    const newPageObjectsPromises = newPages.map(newPage =>
      httpGetJson<TItem[]>(getUrlWithQueryParams(url, {
        page: tableParams?.adjustPage ? newPage - 1 : newPage,
        pageSize: PAGE_SIZE,
      }))
        .then(items => ({ page: newPage, items, isLoading: false }))
    );

    Promise
      .all(newPageObjectsPromises)
      .then(newPageObjects => {
        setLoadedPageObjects(prevPageObjects =>
          newPageObjects
            .reduce(
              (pageObjectsAcc, pageObject) => ({ ...pageObjectsAcc, [pageObject.page]: pageObject }),
              prevPageObjects,
            )
        );
      })
      .finally(() => setLoading(false));
  }, [
    sortedPages,
    loadedPageObjects,
    url,
    currPage,
    tableParams?.scrollRef,
    tableParams?.adjustPage,
    requestToScrollIntoView,
    maxPage,
    loading,
  ]);

  // sync current page and load more on scrolling
  useEffect(() => {
    if (!tableParams?.scrollRef || !url || maxPage == null) {
      return () => { };
    }

    const onScroll = (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      // load more
      const scrollPercentage = scrollTop / (scrollHeight - clientHeight);
      if (scrollPercentage > 0.7 && currPage + 1 <= maxPage) {
        sortedPages.push(currPage + 1);
        setSortedPages([...new Set(sortedPages)]);
      }

      if (scrollPercentage < 0.3 && currPage - 1 > 0) {
        sortedPages.unshift(currPage - 1);
        setSortedPages([...new Set(sortedPages)]);
      }

      // sync current page
      // calculate current page based on scroll position
      const totalItemsOnAllPages = Object.values(loadedPageObjects).reduce(
        (acc, pageObject) => acc + pageObject.items.length,
        0,
      );
      // const itemHeight = scrollHeight / totalItemsOnAllPages;
      const scrollPercentageOfItems = scrollTop / (scrollHeight - clientHeight);
      const amountOfScrolledItems = Math.floor(scrollPercentageOfItems * totalItemsOnAllPages);
      const amountOfScrolledPages = Math.floor(amountOfScrolledItems / PAGE_SIZE);
      const newPage = amountOfScrolledPages + 1;
      if (newPage !== currPage) {
        setPage(newPage);
      }
    };
    const debouncedOnScroll = debounce(onScroll, 500);
    tableParams?.scrollRef?.current?.addEventListener('scroll', debouncedOnScroll);

    return () => {
      tableParams?.scrollRef?.current?.removeEventListener('scroll', debouncedOnScroll);
    };
  }, [tableParams, url, maxPage, currPage, sortedPages, loadedPageObjects]);

  const reset = () => {
    setLoadedPageObjects({});
    setPage(null);
    setSortedPages([]);
    setIsInResetState(true);
  };

  // if url changed, need to start fetching from the first page
  useEffect(() => {
    if (url && maxPage != null && isInResetState) {
      if(!isInResetState) {
        reset();
        setIsInResetState(false);
      }
    }
  }, [url, maxPage, isInResetState]);

  return {
    items: sortedPages.map(p => loadedPageObjects[p]?.items || []).flat(),
    loading: loading,
    isCurrentPageLoading: !loadedPageObjects[currPage] || loadedPageObjects[currPage].isLoading,
    setPage: (page: number) => {
      setRequestToScrollIntoView(true);
      setPage(page);
    },
    page: currPage,
    reset: () => {
      setLoadedPageObjects({});
      setPage(null);
      setSortedPages([]);
    },
  };
}
