import { useState } from 'react';
import './EntryAnalytics.scss';
import HomeShell from '../components/HomeShell';
import { ServiceRegion } from '../../../shared/appBackend/useMetrics';
import RegionsDropdown from '../components/RegionsDropdown';
import CompaniesWidget from './components/CompaniesWidget';
import SitesWidget from './components/SitesWidget';
import UsersWidget from './components/UsersWidget';
import CallRateWidget from './components/CallRateWidget';
import { EntryAnalyticsContext } from './EntryAnalyticsContext';
import { EntryRegion, EntryRegionType, EntryRegionTypeRequired } from '../../../shared/backend/urls';
import AppCompaniesSelect from '../../../shared/appUIFramework/components/AppCompaniesSelect';
import AppSitesSelect from '../../../shared/appUIFramework/components/AppSitesSelect';
import { ICompany } from '../../../shared/appBackend/useCompanies';
import { ISite } from '../../../shared/appBackend/useSites';
import CallTimingsWidget from './components/CallTimingsWidget';
import MobileTypeDropdown, { MobileTypeFilter } from '../components/MobileTypeDropdown';
import AppMonthRangePicker from '../../../shared/appUIFramework/components/AppMonthRangePicker';

function serviceRegionToApiRegion(serviceRegion: ServiceRegion): EntryRegionType {
  const map: Record<ServiceRegion, EntryRegionType> = {
    [ServiceRegion.All]: null,
    [ServiceRegion.Benelux]: 'benelux',
    [ServiceRegion.UnitedKingdom]: EntryRegion.UnitedKingdom,
    [ServiceRegion.Ireland]: EntryRegion.Ireland,
    [ServiceRegion.UnitedStates]: EntryRegion.UnitedStates,
    [ServiceRegion.Netherlands]: EntryRegion.Netherlands,
    [ServiceRegion.Belgium]: EntryRegion.Belgium,
    [ServiceRegion.Luxembourg]: EntryRegion.Luxembourg,
    [ServiceRegion.Germany]: EntryRegion.Germany,
    [ServiceRegion.France]: EntryRegion.France,
    [ServiceRegion.SouthAfrica]: EntryRegion.SouthAfrica,
    [ServiceRegion.Canada]: EntryRegion.Canada,
    [ServiceRegion.Sweden]: EntryRegion.Sweden,
    [ServiceRegion.Norway]: EntryRegion.Norway,
    [ServiceRegion.Denmark]: EntryRegion.Denmark,
  };

  return map[serviceRegion];
}

function apiRegionToServiceRegion(region: EntryRegionType): ServiceRegion {
  const map: Record<EntryRegionTypeRequired, ServiceRegion> = {
    benelux: ServiceRegion.Benelux,
    [EntryRegion.UnitedKingdom]: ServiceRegion.UnitedKingdom,
    [EntryRegion.Ireland]: ServiceRegion.Ireland,
    [EntryRegion.UnitedStates]: ServiceRegion.UnitedStates,
    [EntryRegion.Netherlands]: ServiceRegion.Netherlands,
    [EntryRegion.Belgium]: ServiceRegion.Belgium,
    [EntryRegion.Luxembourg]: ServiceRegion.Luxembourg,
    [EntryRegion.Germany]: ServiceRegion.Germany,
    [EntryRegion.France]: ServiceRegion.France,
    [EntryRegion.SouthAfrica]: ServiceRegion.SouthAfrica,
    [EntryRegion.Canada]: ServiceRegion.Canada,
    [EntryRegion.Sweden]: ServiceRegion.Sweden,
    [EntryRegion.Norway]: ServiceRegion.Norway,
    [EntryRegion.Denmark]: ServiceRegion.Denmark,
  };

  return region == null ? ServiceRegion.All : map[region];
}

export default function EntryAnalytics() {
  const [region, setRegion] = useState<ServiceRegion>(ServiceRegion.All);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [site, setSite] = useState<ISite | null>(null);
  const [mobileTypeFilter, setMobileTypeFilter] = useState<MobileTypeFilter>(MobileTypeFilter.All);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const changeCompany = (company: ICompany | null) => {
    setCompany(company);
    setSite(null);
    setRegion(apiRegionToServiceRegion(company?.entryRegion || null));
  };

  return (
    <HomeShell activeTab='entry-analytics'>
      <div className='app-d-flex app-gap-30 app-entry-analytisc-filters'>
        <AppCompaniesSelect enableReset company={company} setCompany={changeCompany} />
        <AppSitesSelect enableReset site={site} setSite={setSite} companyId={company?.id} />
        <RegionsDropdown region={region} setRegion={setRegion} disabled={!!company} />
        <AppMonthRangePicker
          startDateForm={startDate}
          setStartDateForm={setStartDate}
          endDateForm={endDate}
          setEndDateForm={setEndDate}
        />
        {!!company && <MobileTypeDropdown mobileTypeFilter={mobileTypeFilter} setMobileTypeFilter={setMobileTypeFilter} />}
      </div>
      <EntryAnalyticsContext.Provider
        value={{
          entryRegion: serviceRegionToApiRegion(region),
          company,
          site,
          mobileTypeFilter,
          startDate: startDate,
          endDate: endDate,
        }}
      >
        <div className='app-analytics-widgets'>
          <CompaniesWidget />
          <SitesWidget />
          {site && <UsersWidget />}
        </div>
        <div className='app-d-flex app-gap-30'>
          {!site && <UsersWidget />}
          <CallRateWidget width50={!!site} />
          <CallTimingsWidget width50={!!site} />
        </div>
      </EntryAnalyticsContext.Provider>
    </HomeShell>
  );
}
