import { useTranslation } from 'react-i18next';
import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';
import { closePopup } from '../../shared/appUIFramework/popup/AppPopup';
import { Fragment } from 'react/jsx-runtime';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useState } from 'react';

// START COPIED from entry-cloud-qa-automation
export enum TestRunResult {
  Passed = 'Passed',
  Failed = 'Failed',
  Skipped = 'Skipped',
  NotImplemented = 'NotImplemented',
}

interface TestDescriptor {
  tags: string[];
  logs: string;
  result: TestRunResult;
  runAt: string;
}

export interface ITestRunTreeDescriptor {
  [service: string]: {
    [endpoint: string]: {
      [category: string]: {
        [description: string]: TestDescriptor;
      };
    };
  };
}

export function writeToNode(
  tree: ITestRunTreeDescriptor,
  service: string,
  endpoint: string,
  category: string,
  description: string,
  node: TestDescriptor,
) {
  if (!tree[service]) {
    tree[service] = {};
  }

  if (!tree[service][endpoint]) {
    tree[service][endpoint] = {};
  }

  if (!tree[service][endpoint][category]) {
    tree[service][endpoint][category] = {};
  }

  tree[service][endpoint][category][description] = node;
}

export function enumerateNodes(
  tree: ITestRunTreeDescriptor,
  onNode: ({
    service,
    endpoint,
    category,
    description,
    testRun,
  }: {
    service: string;
    endpoint: string;
    category: string;
    description: string;
    testRun: TestDescriptor;
  }) => void,
) {
  Object.keys(tree).forEach(service => {
    Object.keys(tree[service]).forEach(endpoint => {
      Object.keys(tree[service][endpoint]).forEach(category => {
        Object.keys(tree[service][endpoint][category]).forEach(description => {
          const testRun = tree[service][endpoint][category][description];
          onNode({ service, endpoint, category, description, testRun });
        });
      });
    });
  });
}

export function Test(
  test: {
    onGoToAllClick: () => void;
  } & ITestComponent_TestProps,
) {
  return (
    <div className='app-overflow-scroll'>
      <div>
        <div className='app-weight-600 app-font-28'>
          { test.service } - { test.endpoint } - { test.category } - { test.description }
        </div>
        <div className='app-weight-600 app-font-28'>
          see full log below or&nbsp;
          <button
            onClick={ test.onGoToAllClick }
            className='app-btn-link app-font-28'
          >
            go to all tests
          </button>
        </div>
      </div>
      <div
        className='app-mt-11 app-p-12 app-weight-600 app-font-20'
        dangerouslySetInnerHTML={ {
          __html: test.logs,
        } }
      >
      </div>
    </div>
  );
}

export function Results(
  { onTestClick, results }: {
    onTestClick: (testDescriptor: ITestComponent_TestProps) => void;
    results: ITestRunTreeDescriptor;
  },
) {
  return (
    <div className='app-d-flex app-flex-column app-gap-10'>
      { Object.keys(results).map(service => {
        return (
          <Fragment key={ service }>
            { Object.keys(results[service]).map(endpoint => {
              return (
                <Fragment key={ endpoint }>
                  { Object.keys(results[service][endpoint]).map(category => {
                    return (
                      <Fragment key={ category }>
                        { Object.keys(results[service][endpoint][category]).map(description => {
                          return (
                            <div key={ description }>
                              <button
                                onClick={ () =>
                                  onTestClick({
                                    service,
                                    endpoint,
                                    category,
                                    description,
                                    ...results[service][endpoint][category][description],
                                  }) }
                                className='app-font-20 app-btn-link'
                              >
                                { service } - { endpoint } - { category } - { description }
                              </button>
                            </div>
                          );
                        }) }
                      </Fragment>
                    );
                  }) }
                </Fragment>
              );
            }) }
          </Fragment>
        );
      }) }
    </div>
  );
}

// END COPIED from entry-cloud-qa-automation

interface ITestComponent_TestProps extends TestDescriptor {
  service: string;
  endpoint: string;
  category: string;
  description: string;
}

export default function ServiceHealthReportPopup({ failedTests }: { failedTests: ITestRunTreeDescriptor }) {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const [resultDetails, setResultDetails] = useState<ITestComponent_TestProps | null>(null);

  return (
    <AppPopupLayout
      headerTitle={ t('HealthReport') }
      extraClass='app-service-health-report-popup'
      onHeaderClose={ cancel }
      popupBody={ 
        <>
          <AppShowLoading showLoading={ !failedTests }>
            { failedTests && !resultDetails && (
              <div>
                <div className='app-font-28 app-mb-20'>Failed tests during health check run:</div>
                <Results results={ failedTests } onTestClick={ setResultDetails } />
              </div>
            ) }
            { resultDetails && <Test { ...resultDetails } onGoToAllClick={ () => setResultDetails(null) } /> }
          </AppShowLoading>
        </>
       }
    />
  );
}
